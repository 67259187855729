<template>
  <v-data-table
    :headers="headers"
    :items="audienceData"
    :item-class= "getRowClasses"
    style="height:100%"
    :hide-default-footer="audienceData.length < 15"
    :items-per-page="15"
  >
    <!-- formate table headers -->
    <template v-for="h in headers" v-slot:[`header.${h.value}`]>
      <v-tooltip :key="h.value" bottom>
        <template v-slot:activator="{ on }">
            <span v-on="on" class="headerContent">{{h.text}}</span>
        </template>
        <TooltipDescription :title="h.text" :desc_2="h.desc"></TooltipDescription>
      </v-tooltip>
    </template>

    <!-- format all plain numeric columns -->
    <template v-for="C in titleColumns" v-slot:[`item.${C}`]="{item}">
      <div :key='C.value' class='numericTableCell' v-if="item['type'] !== 'AII'">
        {{ item[C] ? item[C].toLocaleString() : 'n/a' }}
      </div>
      <div :key='C.value' class='numericTableCell' style="padding-left: 50%" v-else>
        {{ item[C] }}
      </div>
    </template> 

    <!-- format all plain numeric columns -->
    <template v-for="numCol in numericColumns" v-slot:[`item.${numCol}`]="{item}">
      <div :key='numCol.value' class='numericTableCell'>
        {{ item[numCol] ? item[numCol].toLocaleString('en-US', {maximumFractionDigits:0}) : 'n/a' }}
      </div>
    </template> 

    <!-- format all percentage columns -->
    <template v-for="pctgCol in percentageColumns" v-slot:[`item.${pctgCol}`]= "{item}">
      <div :key='pctgCol.value' class='numericTableCell'>
        {{
          item[pctgCol] ? item[pctgCol].toLocaleString('en-US', {maximumFractionDigits:2, minimumFractionDigits:0}) + '%' : 'n/a'
        }}
      </div>
    </template>
    <!-- format all percentage columns requiring multiplication by 100 -->
    <template v-for="pctgCol2 in percentageColumnsReqMult" v-slot:[`item.${pctgCol2}`]= "{item}">
      <div :key='pctgCol2.value' class='numericTableCell'>
        {{
          item[pctgCol2] ? (item[pctgCol2] * 100).toLocaleString('en-US', {maximumFractionDigits:2, minimumFractionDigits:0}) + '%' : 'n/a'
        }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import input_data from "@/input_data/Audiences/input_data.json"

import TooltipDescription from "@/components/Utility/TooltipDescription.vue";

function getRowClasses(item) {
  if (item.type == "P") {
    return 'highlightedRow';
  } 
}
export default {
    name: 'AudienceStatTable',
    props: [
      "audienceData"
    ],
    components: {
      TooltipDescription
    },
    methods: {
      getRowClasses
    },
    data: () => ({
      headers: input_data["headers"]
    }),
    computed: {
      percentageColumns: function(){
        return [ 'overlap', 'unaware', 'aware', 'converted' ];
      },
      percentageColumnsReqMult: function(){
        return ['conversion_rate'];
      },
      numericColumns: function(){
        return [ 'population', 'wsi' ];
      },
      titleColumns: function(){
        console.log(this.title_cols)
        return ['name'];
      }
    }
}
</script>

<style>
  .page-title{
      font-family: 'Poppins' !important;
      font-weight: 800;
      font-size: 2em;
  }
  .audience-tool-navigation-bar{
      font-family: 'Poppins' !important;
      font-weight: 600 !important;
      font-size: 0.2em !important;
  }

  .highlightedRow {
    background-color: #0A8C4E80 ;
  }

  .highlightedRow:hover {
    background-color: #0A8C4E !important; ;
  }

  .v-data-table__wrapper > table > thead > tr > th {
    min-width: 130px;
  }

.text-start {
  font-weight: 600;
  font-family: 'Poppins';
  white-space: nowrap;
    
  .headerContent {
    font-weight: 200 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    min-width: 80px;
    text-align: center;
  }

  .numericTableCell {
    text-align: right;
    padding-right: 40%;
  }
}

</style>