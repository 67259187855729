<template>
  <v-container
    fluid
    class="ma-0 pa-6 pt-1"
    style="width: 100%; height: 100%; max-height: 100%;"
  >
    <v-row class="ma-0 pa-0">
      <!-- Info Table-->
      <v-col cols="7" class="ma-0 pa-3">
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0 px-3" cols="8">
            <v-select
              class="audience_load_bar_title"
              v-model="viewing_aud"
              :items="available_audiences"
              @input="update_scenario($event)"
              :item-value="'audience_id'"
              :item-text="'audience_name'"
              :item-disabled="'disable'"
              persistent-hint
              hint="View An Audience"
              return-object
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-row class="ma-3 pa-0" v-on="on" v-bind="attrs">
                  <v-col cols="12" class="ma-0 pa-0">
                    <span class="audience_load_bar_title">{{ item.audience_name }}</span>
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0 pl-3">
                    <span class="audience_load_bar_text">{{
                      item.audience_id == viewing_aud.audience_id
                        ? "viewing"
                        : item.audience_state
                    }}</span>
                  </v-col>
                </v-row>
              </template>
            </v-select>
            <!-- <span class="viewing_audience_title">{{viewing_aud.audience_name}}</span> -->
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <AudienceStatTable :audienceData="audience_table_data" />
      </v-col>
      <!--Network Graph-->
      <v-col cols="5" class="ma-0 pa-3">
        <AudienceGraph
          :audience_info="viewing_aud"
          :audience_data_graph_imgs="graph_img_urls"
          :audience_data="audienceData['audience_overview']"
          v-on:graphIndexChanged="graphIndexOnChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AudienceGraph from "./AudienceGraph.vue";
import AudienceStatTable from "./AudienceStatTable.vue";
export default {
  name: "AudienceStatsBasePage",
  components: {
    AudienceGraph,
    AudienceStatTable,
  },
  props: ["audiences", "audience_info", "audience_data"],
  mounted() {
    this.available_audiences = [];
    for (let i = 0; i < this.audiences.length; i++) {
      if (this.audiences.audience_state !== "designing") {
        this.available_audiences.push(this.audiences);
      }
    }
    this.available_audiences = [];
    for (let i = 0; i < this.audiences.length; i++) {
      if (this.audiences[i].audience_state !== "designing") {
        let tmp = Object.assign({}, this.audiences[i]);
        if (tmp.audience_state !== "built") {
          tmp["disable"] = true;
        } else {
          tmp["disable"] = false;
        }
        this.available_audiences.push(tmp);
      }
      
    }
    // this.available_audiences = this.audiences;
    this.viewing_aud = this.audience_info;
    this.audienceData = this.audience_data;
    this.graphIndexOnChange(0);
  },
  watch: {
    audience_info: {
      handler: function (updated_audience) {
        this.viewing_aud = updated_audience;
      },
      deep: true,
    },
    audience_data: {
      handler: function (updated_audience) {
        this.audienceData = updated_audience;
        this.graph_img_urls = Object.values(updated_audience["audience_graphs"]);
        this.graphIndexOnChange(this.viewing_aud["graph_start"]);
      },
      deep: true,
    },
    audiences: {
      handler: function (updated) {
        this.available_audiences = [];
        for (let i = 0; i < updated.length; i++) {
          if (updated[i].audience_state !== "designing") {
            let tmp = Object.assign({}, updated[i]);
            if (updated[i].audience_state !== "built") {
              tmp["disable"] = true;
            } else {
              tmp["disable"] = false;
            }
            this.available_audiences.push(tmp);
          }
        }
      },
      deep: true,
    },
  },
  data: () => ({
    audienceData: {},
    audience_table_data: [],
    viewing_aud: {},
    graph_img_urls: [],
    available_audiences: [],
  }),
  methods: {
    graphIndexOnChange(value) {
      let newAudienceData = [];
      // for (let i = 0; i <= value; i++) {
      if (
        Object.keys(this.audienceData).includes("audience_statistics") &&
        Object.keys(this.audienceData["audience_statistics"]).includes(value.toString())
      ) {
        newAudienceData = this.audienceData["audience_statistics"][value];
      }
      // }
      this.audience_table_data = newAudienceData;
      this.$emit("graphIndexChanged", value);
    },
    update_scenario: function (item) {
      this.$emit("viewing_audience", item.audience_id);
    },
  },
};
</script>

<style>
.viewing_audience_title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.2em;
}
</style>
